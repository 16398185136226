import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {Checkbox, Select, MenuItem} from '@material-ui/core';

import {listCompetition, readCompetitionResult} from './Data/CompUniversityProcess';
import {getTitles} from './Data/Title';
import LoadingCircle from '../../../Component/LoadingCircle';
import {fn, } from '../../../Functions';

import './CompUniversityAdcuts.scss';

const competitionGroups = [{}]
const UNIVERSITY_CONTEST_YEAR_LIST = ['2022','2023','2024'];


const University = () => {
    let dispatch = useDispatch();

    const [isLoading, setIsLoading] = React.useState(false);

    const [candidates, setCandidates] = React.useState([]);
    const [titleInfo, setTitleInfo] = React.useState({});

    const [results, setResults] = React.useState([]);

    const [filterDeleted, setFilterDeleted] = React.useState(true);
    const [filterTest, setFilterTest] = React.useState(true);
    const [filterFiltered, setFilterFiltered] = React.useState(true);

    const [showSchool, setShowSchool] = React.useState(false);
    const [showAuthor, setShowAuthor] = React.useState(false);
    const [showEmail,  setShowEmail]  = React.useState(false);

    const [universityContestYear, setUniversityContestYear] = React.useState(UNIVERSITY_CONTEST_YEAR_LIST.at(-1));

    const selectUniversityYear = (event) => {
        setUniversityContestYear(event.target.value);
    }

    useEffect(()=>{
        document.title = `${universityContestYear} 과제부활전`;
    },[universityContestYear]);

    React.useEffect(()=>{

        (async ()=>{
            setIsLoading(true);

                let candi = await listCompetition();
                setCandidates(candi);

                let titleList = await getTitles(candi);

                let titleObj = titleList.map(row => ({[`${row.serviceId}:${row.titleId}`]: row})).reduce((a,b)=> Object.assign(a,b), {});
                setTitleInfo(titleObj);

                try{
                    let resultList = await readCompetitionResult(candi);
                    let resultObj = resultList.map(({serviceId, titleId, episodeId, snapshotId, ...other}) => ({[[serviceId, titleId, episodeId, snapshotId].join(':')]: {serviceId, titleId, episodeId, snapshotId, ...other}})).reduce((a,b)=> Object.assign(a,b), {});
                    setResults(resultObj);
                }catch(e){
                    //
                }
            setIsLoading(false);
        })();
        
    },[dispatch])

    const isBetweenTimestamp = (createdAt, startTS, endTS) => {
        if(createdAt >= startTS && createdAt <= endTS){
            return true;
        }
        return false;
    }

    return (<div className={"CompetitionAdcuts"}>
        <div style={{display: 'flex', flexDirection:'row-reverse', paddingRight: 20, justifyContent:'flex-start', alignItems:'center'}}>
            <Checkbox checked={filterDeleted} onChange={(event, checked) => setFilterDeleted(checked)}/>
            <label>{"삭제된 항목 숨기기"}</label>
            <Checkbox checked={filterTest} onChange={(event, checked) => setFilterTest(checked)}/>
            <label>{"테스트 항목 숨기기"}</label>
            <Checkbox checked={filterFiltered} onChange={(event, checked) => setFilterFiltered(checked)}/>
            <label>{"필터링된 항목 숨기기"}</label>
        </div>
        <div style={{display: 'flex', flexDirection:'row-reverse', paddingRight: 20, justifyContent:'flex-start', alignItems:'center'}}>
            <Checkbox checked={showSchool} onChange={(event, checked) => setShowSchool(checked)}/>
            <label>{"학교"}</label>
            <Checkbox checked={showAuthor} onChange={(event, checked) => setShowAuthor(checked)}/>
            <label>{"작가명"}</label>
            <Checkbox checked={showEmail} onChange={(event, checked) => setShowEmail(checked)}/>
            <label>{"이메일"}</label>
        </div>
        <div className={'SelectUniversityYearBox'}>
            <span>{'과제부활전 년도 선택'}</span>
            <Select
                className={'SelectUniversityYear'}
                value={universityContestYear}
                onChange={selectUniversityYear}
            >
                {UNIVERSITY_CONTEST_YEAR_LIST.map(value => (
                    <MenuItem key={value} value={value}>{value}</MenuItem>
                ))}
            </Select>
        </div>
        {competitionGroups.map(() => {
            let startTSFor2022 = new Date("2022-12-19 00:00:00+09:00").getTime()/1000;
            let endTSFor2022 = new Date("2023-01-01 00:00:00+09:00").getTime()/1000;
            let startTSFor2023 = new Date("2023-12-18 00:00:00+09:00").getTime()/1000;
            let endTSFor2023 = new Date("2024-01-01 00:00:00+09:00").getTime()/1000;
            let startTSFor2024 = new Date("2024-12-16 00:00:00+09:00").getTime()/1000;
            let endTSFor2024 = new Date("2025-01-01 00:00:00+09:00").getTime()/1000;
            let weekIter = `${universityContestYear} 과제부활전`;
            let subsetCandidates = candidates.filter(row =>
                   (!filterDeleted || titleInfo[`${row.serviceId}:${row.titleId}`])
                && (!filterFiltered || !row.validatedAt || row.isValid)
                && (!filterTest || !titleInfo[`${row.serviceId}:${row.titleId}`] || (titleInfo[`${row.serviceId}:${row.titleId}`].title || '').indexOf('(테스트)') < 0)
                && row.year === universityContestYear
            )
            
            console.log(subsetCandidates
                .map(candidate => {
                    let title = {...(titleInfo[`${candidate.serviceId}:${candidate.titleId}`] || {})};
                    if (!isBetweenTimestamp(candidate.createdAt, startTSFor2022, endTSFor2022) 
                        && !isBetweenTimestamp(candidate.createdAt, startTSFor2023, endTSFor2023) 
                        && !isBetweenTimestamp(candidate.createdAt, startTSFor2024, endTSFor2024) 
                        && title.title){
                        title.title = "(기간외)"+title.title;
                    }
                    let result = results[[candidate.serviceId, candidate.titleId, candidate.episodeId, candidate.snapshotId].join(':')] || {};
                    return {...title, ...candidate, ...result};
                }))

            return (<div className={'SubsetWrapper'} >
                <div className={"CompetitionHeader"} >
                    <h2 className={"SubsetHeader"}>{weekIter}</h2><span>{`${
                        ((num)=>(num>0?`전체: ${num} 개`:``))(subsetCandidates.length)
                    }`}</span>
                </div>
                <div className={"CompetitionBody"} >
                    {subsetCandidates
                    .map(candidate => {
                        let title = {...(titleInfo[`${candidate.serviceId}:${candidate.titleId}`] || {})};
                        if (!isBetweenTimestamp(candidate.createdAt, startTSFor2022, endTSFor2022) 
                            && !isBetweenTimestamp(candidate.createdAt, startTSFor2023, endTSFor2023) 
                            && !isBetweenTimestamp(candidate.createdAt, startTSFor2024, endTSFor2024) 
                            && title.title){
                            title.title = "(기간외)"+title.title;
                        }
                        let result = results[[candidate.serviceId, candidate.titleId, candidate.episodeId, candidate.snapshotId].join(':')] || {};
                        return {...title, ...candidate, ...result};
                    }).map((row, index) => {
                        if ( row.slides.length < 1 ) {
                            return <></>;
                        }
                        return (
                            <div key={`${weekIter} subset`} className={"CompetitionSubset"}>
                                <div className={'SubsetRow'} >
                                    <div className={"AdcutSlider"} >
                                        {row.slides.map( ({image}, indexx) => 
                                            <div style={{boxSizing:"border-box", padding: 1, display: 'flex', justifyContent: 'cneter', alignItems: 'center'}} key={image + indexx} >
                                                <img src={image} alt={'slide'} width={150} />
                                            </div>
                                        )}
                                    </div>
                                    <div className={'AdcutButtonBox'} >
                                        <button className={'ContentsButton'} onClick={() => fn.goto(`/challenge/contents/${row.serviceId}/${row.titleId}`)} >{"작품정보"}</button>
                                        <button className={'FirstEpisodeButton'} onClick={() => fn.goto(`/challenge/contents/${row.serviceId}/${row.titleId}/${row.episodeId}`)} >{"첫 화"}</button>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>)
        })}
        <LoadingCircle show={isLoading}/>
    </div>);
}

export default University;


