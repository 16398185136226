import React from 'react';
import './SendEmail.scss'
import {  Button } from '@material-ui/core';
import { triggerEmail } from '../../../../Data/TriggerEmail';
import ss from '../../../../Data/Session';
import PerformanceCard from './PerformanceCard';

/**
 * 
 * @param {{
 *  author: string,
 *  weekIter: string,
 *  noticeLocation: string,
 *  email: string,
 *  sendCallback: (boolean)=>void
 * }} param0 
 * @returns 
 */
const CompetitionEncourage = ({title, author, weekIter, token, isWinner, noticeLocation, email, serviceId, titleId, episodeId, snapshotId, thumbnail, card = {}, sendCallback}) => {
    const hasCard = Object.keys(card).length > 0;
    const body = `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml">
        <head>
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </head>
        <body>
            <div style="background-color: rgb(255, 255, 255); padding: 24px 0 40px 0;">
                <div>
                    <div style="margin: 0 auto; max-width: 630px; background-color: rgb(255, 255, 255); border: 1px solid rgb(236,240,241); border-radius: 12px;">
                        <div>
                            <div style="margin:0 auto; padding: 0 16px; max-width: 598px;">
                                <div style="max-width: 430px; margin: 0 auto;">
                                    <img src="https://static.webtoon.today/ddah/과제부활전2024_로고_이메일.png" alt="homepage"
                                        style="margin: 80px 0 20px 0; width: 100%; object-fit: contain;" />
                                </div>
                                <hr style="border: 1px solid rgb(51, 51, 51); margin: 10px 0;" />
                                <div>
                                    <div style="padding: 24px 0 16px 0; font-size: 24px; font-weight: 600; line-height: 26px; color: rgb(25,24,27); word-wrap: break-word; word-break: keep-all;">
                                        2024 과제부활전 결과 발표
                                    </div>
                                    <div style="display: flex; justify-content: center;">
                                        <img src="https://static.webtoon.today/ddah/2024_university/card/${serviceId}_${titleId}.png" alt="img:${title}" width="340" height="340" style="border-radius: 8px; box-sizing: border-box;" />
                                    </div>                                    <div style="padding: 24px 0 16px 0; font-size: 16px; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); ">
                                        안녕하세요, <span style="color: rgb(60, 70, 81); font-weight: 700;">${author}</span>작가님. 오늘의웹툰입니다.
                                    </div>
                                    <div style="padding: 0 0 16px 0; font-size: 16px; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); word-wrap: break-word; word-break: keep-all;">
                                        <span style="color: rgb(60, 70, 81); font-weight: 700;">&lt;${title}&gt;</span>작품으로 참여해 주신 과제부활전 결과가 발표됐습니다.<br />
                                        <a href="https://challenge.webtoon.today/univ2024" style="color: rgb(61, 106, 255); font-weight: 700; text-decoration-line: underline;">[공모전 결과 페이지]</a>에서 수상작을 확인하실 수 있습니다.
                                    </div>
                                    <div style="padding: 0 0 24px 0; font-size: 16px; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); word-wrap: break-word; word-break: keep-all;">
                                        공모전 기간 동안 진행된 &lt;${title}&gt;의 데이터 분석 결과는 아래의 버튼을 통해 확인하실 수 있으며,
                                        <a href="https://static.webtoon.today/ddah/210621-report%20review.pdf" style="font-weight: 700; text-decoration-line: underline; color: rgb(121, 120, 130);">데이터 분석 리포트 설명 파일</a>에 상세한 설명을 첨부해두었습니다.
                                    </div>
                                    ${isWinner === 'fail' ?
                                    `
                                    <div style="padding: 0 0 24px 0; font-size: 16px; font-style: normal; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); word-wrap: break-word; word-break: keep-all;">
                                        마지막으로 매월 개최하고 있는 ‘월간 공모전’에도 많은 관심 부탁드리며, 과제부활전 2024를 위해 소중한 시간 내어주셔서 감사드립니다.
                                    </div>
                                    <div style="padding: 0 0 16px 0; font-size: 16px; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); word-wrap: break-word; word-break: keep-all;">
                                        오늘의웹툰은 언제나 ${author} 작가님의 창작을 응원하겠습니다.
                                    </div>
                                    `
                                    :
                                    `
                                    <div style="padding: 0 0 16px 0; font-size: 16px; font-style: normal; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); word-wrap: break-word; word-break: keep-all;">
                                        모든 작품들은 동일한 기준으로 마케팅을 집행하였으나, 접수해주신 &lt;${title}&gt; 작품은 데이터 분석에 충분한 데이터를 수집하지 못해서 아쉽게도 심사에서 제외 처리 되었음을 알려드립니다.
                                    </div>
                                    <div style="padding: 0 0 16px 0; font-size: 16px; font-style: normal; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); word-wrap: break-word; word-break: keep-all;">
                                        보고서에 첨부된 제안을 참고하셔서, 원고 또는 광고 이미지를 수정하신 후 다시 데이터 분석을 신청해 주시면 보다 상세한 분석을 제공해 드리겠습니다.
                                    </div>
                                    <div style="padding: 0 0 24px 0; font-size: 16px; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); word-wrap: break-word; word-break: keep-all;">
                                        다시 한번 과제부활전 2024를 위해 소중한 시간 내어주셔서 감사드립니다.
                                    </div>
                                    <div style="padding: 0 0 16px 0; font-size: 16px; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); word-wrap: break-word; word-break: keep-all;">
                                        오늘의웹툰은 언제나 ${author} 작가님의 창작을 응원하겠습니다.
                                    </div>
                                    `
                                    }
                                    
                                    <div style="max-width: 180px; padding: 16px 0 24px 0; margin: 0 auto;">
                                        <div>
                                            <a href="https://challenge.webtoon.today/report/${serviceId}/${titleId}/${episodeId}/${snapshotId}" style="text-decoration: none;">
                                                <img src="https://static.webtoon.today/ddah/mailing/component/btn_result_notice.png" alt="데이터 분석 결과 보기" style="height: 40px; object-fit:contain;" />
                                            </a>
                                        </div>
                                    </div>
                                    <div style="margin: 10px auto; padding: 10px 16px; max-width: 598px; border-top: 1px solid rgb(237, 236, 238); border-bottom: 1px solid rgb(237, 236, 238);">
                                        <div style="padding: 0 12px 4px 12px; font-size: 16px; font-style: normal; font-weight: 700; line-height: 24px; color: rgb(60, 70, 81);">담당자</div>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div style="width: 52px; height: 52px; border-radius: 5px; padding: 0 12px;">
                                                            <img src="${ss.getCurrentSession().image}" alt="${ss.getCurrentSession().name}" style="width: 100%;">
                                                        </div>
                                                    </td>
                                                    <td style="width: 10px;"></td>
                                                    <td style="font-size: 14px; font-style: normal; font-weight: 500; line-height: 20px;">
                                                        <div style="color: rgb(60, 70, 81)">${ss.getCurrentSession().name} <span style="color: rgb(158, 157, 164)">디렉터</span></div>
                                                        <div style="color: rgb(60, 70, 81)">${ss.getCurrentSession().email}</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div style="margin: 32px auto; padding:0 16px; max-width: 598px;">
                                <div>
                                    <div style="padding-top: 24px; padding-bottom: 10px;">
                                        <div style="padding-bottom: 20px; text-align: center;">
                                            <img src="https://static.webtoon.today/ddah/mailing/logo/logo_square_big.png"
                                                alt="오늘의웹툰" width="62px" height="40px" />
                                        </div>
                                        <div style="text-align: center; font-size: 14px; font-weight: 400; line-height: 20px; color: rgba(158, 157, 164, 1);  word-wrap: break-word; word-break: keep-all;">
                                            사용 중 불편한 점이나 궁금한 사항이 있으시면<br />
                                            언제든 <a href="mailto:help@webtoon.today" style="color: rgba(61, 106, 255, 1);">help@webtoon.today</a>
                                            로 문의주시기 바랍니다.
                                        </div>
                                    </div>
                                    <div style="text-align: center; padding-top: 24px; font-size: 12px; font-weight: 400; line-height: 20px; color: rgba(158, 157, 164, 1);  word-wrap: break-word; word-break: keep-all;">
                                        주식회사 오늘의웹툰 <br />
                                        서울시 강남구 강남대로 78길 33-10, 3층
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </body>
    </html>
    `
    
    const mailTitle = `[오늘의웹툰] ${weekIter} 결과 공지`

    const [isSending, setIsSending] = React.useState(false);

    return (
        <div style={{display: 'flex', justifyContent: 'center', paddingTop: '20px'}}>
            <div className={'SendEmailInner'}>
                <div className={`EmailInfo`} style={{marginBottom:10, fontSize:'0.8rem', borderBottom: '1px black solid'}}>
                    <span style={{fontWeight: 'bold'}}>{"발송 정보"}</span>
                    <table>
                        <tbody>
                            <tr>
                                <td>{"수신인"}</td><td>{email}</td>
                            </tr>
                            <tr>
                                <td>{"제목"}</td><td>{mailTitle}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={`EmailPreview`}>
                    <div dangerouslySetInnerHTML={{__html: body}} />
                </div>
                <div className={'SendButton'}>
                    <Button 
                        disabled={!author || !email || isSending}
                        fullWidth
                        variant={'contained'} color={'primary'} 
                        onClick={ async () => {
                            setIsSending(true);
                            // 배포 직전에 groups 를 artist@webtoontoday.com 으로 바꾸기
                            const result = await triggerEmail(email,mailTitle,body,'artist@webtoontoday.com')
                            if (!result) {
                                alert('이메일 전송에 실패했습니다.')
                            }
                            sendCallback(!!result);
                            setIsSending(false);
                        }}
                    >
                        {'이메일 보내기'}
                    </Button>
                </div>
            </div>
        </div>
        
    )
}

export default CompetitionEncourage;