import React from 'react';
import './SendEmail.scss'
import { Button, MenuItem, Select, TextField } from '@material-ui/core';
import { triggerEmail } from '../../../../Data/TriggerEmail';


const centerAlign = (text) => `
<table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%">
    <tbody>
        <tr>
            <td>
                ${text}
            </td>
        </tr>
    </tbody>
</table>
`
const contestEndUnixTime   = new Date("2025-01-01 00:00:00+09:00").getTime()/1000;
const currentUnixTime      = new Date().getTime()/1000;

const FilteredApplication = ({title, author, email, sendCallback, token, serviceId, titleId}) => {

    const [reason, setReason] = React.useState('');
    const [additionalMessage, setAdditionalMessage] = React.useState("");
    const isContestEnd = currentUnixTime > contestEndUnixTime;

    const body = `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml">
        <head>
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </head>
        <body>
            <div style="background-color: rgb(255, 255, 255); padding: 24px 0 40px 0;">
                ${centerAlign(`
                    <div style="margin: 0 auto; max-width: 630px; background-color: rgb(255, 255, 255); border: 1px solid rgb(236,240,241); border-radius: 12px;">
                        ${centerAlign(`
                            <div style="margin:0 auto; padding: 0 16px; max-width: 598px;">
                                <div style="max-width: 430px; margin: 0 auto;">
                                    <img src="https://static.webtoon.today/ddah/과제부활전2024_로고_이메일.png"
                                        alt="homepage"
                                        style="margin: 80px 0 20px 0; width: 100%; object-fit: contain;" 
                                    />
                                </div>
                                <hr style="border: 1px solid rgb(51, 51, 51); margin: 10px 0;" />
                                <div>
                                    <div style="padding: 24px 0 16px 0; font-size: 24px; font-weight: 600; line-height: 26px; color: rgb(25,24,27); word-wrap: break-word; word-break: keep-all;">
                                        ${isContestEnd ? "2024 과제부활전 접수 작품 최종 반려 안내" : "제출작 반려 안내"}
                                    </div>
                                    <div style="padding: 24px 0 16px 0; font-size: 16px; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); ">
                                        <span style="color: rgb(14, 169, 246); font-weight: 700;">${author}</span> 작가님 안녕하세요.<br/>
                                        오늘의웹툰입니다.
                                    </div>
                                    <div style="padding: 0 0 16px 0; font-size: 16px; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); word-wrap: break-word; word-break: keep-all;">
                                        과제부활전에 접수해 주신 <${title}> 작품을 검토한 결과, 데이터 분석 심의가 어려워 안내드립니다.
                                    </div>
                                    <div style="padding: 0 0 ${isContestEnd?"24px": "16px"} 0; font-size: 16px; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); word-wrap: break-word; word-break: keep-all;">
                                        공모전 심사 기준이 되는 데이터 분석 서비스 이용을 위해서는
                                        <b style="font-weight: 600; color: rgb(25, 24, 27);">다음 조건을 만족하는 1화 이상의 원고와 광고 이미지</b>가 필요합니다. ${isContestEnd ? "따라서 접수가 불가능한 상황을 아래에서 안내해드리려 합니다." : "해당하는 반려 사항을 고려하셔서 수정 후 재접수 부탁드립니다."}
                                    </div>
                                    ${!isContestEnd ? ` 
                                    <div style="padding: 0 0 24px 0; font-size: 16px; font-weight: 600; line-height: 24px; color: rgb(250, 149, 56); text-decoration-line: underline; word-wrap: break-word; word-break: keep-all; ">
                                        31일 오후 2시 이후로는 심의 결과 중간 안내 및 재접수 요청이 이루어지지 않습니다. <br/>
                                        해당 시점에 제출한 작품으로 최종 심의가 이루어지기 때문에 재접수 시 반려되지 않도록 원고와 광고이미지에 각별한 주의를 기울여 주시기 바랍니다.
                                    </div>
                                    `: ``}
                                    <div style="margin: 24px 0 0 0; padding: 12px 0;background-color: rgb(246, 246, 249); border-radius: 8px;">
                                        <div style="padding: 0px 16px 4px 16px; font-size: 16px; font-weight: 700; font-style: normal; line-height: 24px; color: ${reason === '미완성' ? "rgb(235, 81, 71)" : "rgb(25, 24, 27)"};">
                                            ${reason === '미완성' ? '(해당) ':''}완성되지 않은 원고
                                        </div>
                                        <ul style="margin: 0; padding: 0; list-style: none; word-wrap: break-word; word-break: keep-all;">
                                            <li style="margin: 4px 0 4px 32px; font-size: 16px; font-style: normal; font-weight: 500; line-height: 24px; color: rgb(121,120,130);">
                                                콘티, 스케치 단계의 원고
                                            </li>
                                            <li style="margin: 4px 0 4px 32px; font-size: 16px; font-style: normal; font-weight: 500; line-height: 24px; color: rgb(121,120,130);">
                                                채색이 안 된 원고
                                            </li>
                                        </ul>
                                        <div style="padding: 12px 16px 4px 16px; font-size: 16px; font-weight: 700; font-style: normal; line-height: 24px; color: ${reason === '형식/분량문제' ? "rgb(235, 81, 71)" : "rgb(25, 24, 27)"};">
                                            ${reason === '형식/분량문제' ? '(해당) ':''}형식에 맞지 않는 원고
                                        </div>
                                        <ul style="margin: 0; padding: 0; list-style: none; word-wrap: break-word; word-break: keep-all;">
                                            <li style="margin: 4px 0 4px 32px; font-size: 16px; font-style: normal; font-weight: 500; line-height: 24px; color: rgb(121,120,130);">
                                                원고 원고 길이가 60컷 이하이거나, 스크롤 방식으로 보기 불가능한 경우
                                            </li>
                                        </ul>
                                        <div style="padding: 12px 16px 4px 16px; font-size: 16px; font-weight: 700; font-style: normal; line-height: 24px; color: ${reason === '권리없음' ? "rgb(235, 81, 71)" : "rgb(25, 24, 27)"};">
                                            ${reason === '권리없음' ? '(해당) ':''}게재, 송출이 불가한 원고
                                        </div>
                                        <ul style="margin: 0; padding: 0; list-style: none; word-wrap: break-word; word-break: keep-all;">
                                            <li style="margin: 4px 0 4px 32px; font-size: 16px; font-style: normal; font-weight: 500; line-height: 24px; color: rgb(121,120,130);">
                                                독점 게재(연재) 계약이 체결되거나 신청자에게 작품에 대한 권리가 없는 경우
                                            </li>
                                        </ul>
                                        <div style="padding: 12px 16px 4px 16px; font-size: 16px; font-weight: 700; font-style: normal; line-height: 24px; color: ${reason === '광고불가' ? "rgb(235, 81, 71)" : "rgb(25, 24, 27)"};">
                                            ${reason === '광고불가' ? '(해당) ':''}광고가 불가능한 원고 또는 광고 이미지
                                        </div>
                                        <ul style="margin: 0; padding: 0; list-style: none; word-wrap: break-word; word-break: keep-all;">
                                            <li style="margin: 4px 0 4px 32px; font-size: 16px; font-style: normal; font-weight: 500; line-height: 24px; color: rgb(121,120,130);">
                                                작품 내용과 무관하거나 내용을 알 수 없는 경우
                                            </li>
                                            <li style="margin: 4px 0 4px 32px; font-size: 16px; font-style: normal; font-weight: 500; line-height: 24px; color: rgb(121,120,130);">
                                                폭력성, 선정성 등의 이유로 19세 미만 이용 불가한 경우
                                            </li>
                                        </ul>
                                        <div style="padding: 12px 16px 4px 16px; font-size: 16px; font-weight: 700; font-style: normal; line-height: 24px; color: ${reason === '주간독자력' ? "rgb(235, 81, 71)" : "rgb(25, 24, 27)"};">
                                            ${reason === '주간독자력' ? '(해당) ':''}주간 독자력 공모전
                                        </div>
                                        <ul style="margin: 0; padding: 0; list-style: none; word-wrap: break-word; word-break: keep-all;">
                                            <li style="margin: 4px 0 4px 32px; font-size: 16px; font-style: normal; font-weight: 500; line-height: 24px; color: rgb(121,120,130);">
                                                주간 독자력 공모전에서 수상한 작품
                                            </li>
                                            <li style="margin: 4px 0 4px 32px; font-size: 16px; font-style: normal; font-weight: 500; line-height: 24px; color: rgb(121,120,130);">
                                                데이터 분석을 받은 이후로 유의미한 변경이 이루어지지 않은 원고
                                            </li>
                                        </ul>
                                        <div style="padding: 12px 16px 4px 16px; font-size: 16px; font-weight: 700; font-style: normal; line-height: 24px; color: ${reason === '기간외' ? "rgb(235, 81, 71)" : "rgb(25, 24, 27)"};">
                                            ${reason === '기간외' ? '(해당) ':''}기간 외 접수
                                        </div>
                                    </div>
                                    <div style="padding: 24px 0 16px 0; font-size: 16px; font-style: normal; font-weight: 600; line-height: 24px; color: rgb(0, 0, 0);">
                                        ${additionalMessage.replace(/\n/g,"<br/>")}
                                    </div>
                                    ${isContestEnd ? `
                                    <div style="padding: 0 0 24px 0; font-size: 16px; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); word-wrap: break-word; word-break: keep-all;">
                                        과제부활전은 2024년 12월 31일 23:59:59 으로 접수가 종료되어 수정/재접수가 불가능합니다. 추후 위와 같은 사항이 수정된다면 ‘주간 독자력 공모전’, ‘데이터 분석 및 피드백’ 등의 서비스를 이용해 주시면 감사하겠습니다.
                                    </div>
                                    `
                                    :
                                    `
                                    <div style="padding: 0 0 24px 0; font-size: 16px; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); word-wrap: break-word; word-break: keep-all;">
                                        과제부활전은 2024년 12월 31일 23:59:59 으로 접수가 종료되며, 이후 수정/재접수가 불가능합니다. 위와 같은 사항을 고려하셔서, 원고 <b style="font-weight: 600; color: rgb(0, 176, 240)">수정 후</b> 재신청 부탁드립니다.<br/>
                                        감사합니다.
                                    </div>
                                    `
                                    }
                                    <div style="padding: 0 0 24px 0; font-size: 16px; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); word-wrap: break-word; word-break: keep-all;">
                                        오늘의웹툰 팀 드림
                                    </div>
                                    <div style="max-width: 180px; padding: 16px 0 24px 0; margin: 0 auto;">
                                        ${centerAlign(isContestEnd ? `
                                        <a href="https://challenge.webtoon.today/signin?email=${email}&token=${token}&redirectTo=${encodeURIComponent(`/contents/${serviceId}/${titleId}`)}" style="text-decoration: none;">
                                            <img src="https://static.webtoon.today/ddah/mailing/component/btn_goto_webtoonhome.png"
                                                alt="작품 홈에서 확인"
                                                style="height: 40px; object-fit:contain;"
                                            />
                                        </a>
                                        ` 
                                        : 
                                        `
                                        <a href="https://challenge.webtoon.today/signin?email=${email}&token=${token}&redirectTo=${encodeURIComponent(`/analysis/univapply/${serviceId}/${titleId}`)}"
                                            style="text-decoration: none;">
                                            <img src="https://static.webtoon.today/ddah/mailing/component/btn_companion_notice.png"
                                                alt="작품 재접수하기"
                                                style="height: 40px; object-fit:contain;"
                                            />
                                        </a>
                                        `)}
                                    </div>
                                </div>
                            </div>
                            <div style="margin: 32px auto; padding:0 16px; max-width: 598px;">
                                ${centerAlign(`
                                    <div style="padding-top: 24px; padding-bottom: 10px;">
                                        <div style="padding-bottom: 20px; text-align: center;">
                                            <img src="https://static.webtoon.today/ddah/mailing/logo/logo_square_big.png"
                                                alt="오늘의웹툰" 
                                                width="62px" 
                                                height="40px"
                                            />
                                        </div>
                                        <div style="text-align: center; font-size: 14px; font-weight: 400; line-height: 20px; color: rgba(158, 157, 164, 1);  word-wrap: break-word; word-break: keep-all;">
                                            사용 중 불편한 점이나 궁금한 사항이 있으시면<br/>
                                            언제든
                                            <a href="mailto:help@webtoon.today" style="color: rgba(61, 106, 255, 1);">help@webtoon.today</a>
                                            로 문의주시기 바랍니다.
                                        </div>
                                    </div>
                                    <div style="text-align: center; padding-top: 24px; font-size: 12px; font-weight: 400; line-height: 20px; color: rgba(158, 157, 164, 1);  word-wrap: break-word; word-break: keep-all;">
                                        주식회사 오늘의웹툰 <br/>
                                        서울시 강남구 강남대로 78길 33-10, 3층
                                    </div>
                                `)}
                            </div>
                        `)}
                    </div>
                `)}
            </div>
        </body>
    </html>
    `;
    
    const mailTitle = isContestEnd ? `[오늘의웹툰] 2024 과제부활전 원고 심의결과 안내` : `[오늘의웹툰] 2024 과제부활전 재접수를 요청드립니다.`

    const [isSending, setIsSending] = React.useState(false);

    return (
        <div style={{display: 'flex', justifyContent: 'center', paddingTop: '20px'}}>
            <div className={'SendEmailInner'}>
                <div className={`EmailInfo`} style={{marginBottom:10, fontSize:'0.8rem', borderBottom: '1px black solid'}}>
                    <span style={{fontWeight: 'bold'}}>{"발송 정보"}</span>
                    <table>
                        <tbody>
                            <tr>
                                <td>{"수신인"}</td><td>{email}</td>
                            </tr>
                            <tr>
                                <td>{"제목"}</td><td>{mailTitle}</td>
                            </tr>
                            <tr>
                                <td>{"반려 이유"}</td>
                                <td>
                                    <Select value={reason} onChange={(e)=> setReason(e.target.value)}>
                                        <MenuItem value={"미완성"} >{"완성되지 않은 원고"}</MenuItem>
                                        <MenuItem value={"형식/분량문제"} >{"형식이 맞지 않는 원고"}</MenuItem>
                                        <MenuItem value={"권리없음"} >{"게재, 송출이 불가능한 원고"}</MenuItem>
                                        <MenuItem value={"광고불가"} >{"광고가 불가능한 원고"}</MenuItem>
                                        <MenuItem value={"주간독자력"} >{"주간 독자력 공모전"}</MenuItem>
                                        <MenuItem value={"기간외"} >{"기간 외 접수"}</MenuItem>
                                    </Select>
                                </td>
                            </tr>
                            <tr>
                                <td>{"추가 안내사항(선택)"}</td>
                                <td><TextField fullWidth multiline value={additionalMessage} onChange={(event)=>{setAdditionalMessage(event.target.value)}}/></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={`EmailPreview`}>
                    <div dangerouslySetInnerHTML={{__html: body}} />
                </div>
                <div className={'SendButton'}>
                    <Button 
                        fullWidth
                        disabled={!title || !author || !email || isSending || !reason}
                        variant={'contained'} color={'primary'} 
                        onClick={ async () => {
                            setIsSending(true);
                            // 배포 직전에 groups 를 artist@webtoontoday.com 으로 바꾸기
                            const result = await triggerEmail(email,mailTitle,body,'artist@webtoontoday.com')
                            if (!result) {
                                alert('이메일 전송에 실패했습니다.')
                            }
                            sendCallback(!!result, `${reason}${additionalMessage?`(${additionalMessage})`:``}`);
                            setIsSending(false);
                        }}
                    >
                        {'이메일 보내기'}
                    </Button>
                </div>
            </div>
        </div>
        
    )
}

export default FilteredApplication ;