import React from 'react';
import { Button, TextField } from '@material-ui/core';
import { triggerEmail } from '../../../Data/TriggerEmail';

// eslint-disable-next-line no-empty-pattern
const NoticeByEmail = ({}) => {

    const [message, setMessage] = React.useState('');

    const [mailListText, setMailListText] = React.useState('');
    const [mailMergeList, setMailMergeList] = React.useState([]);
    React.useEffect(()=>{
        setMailMergeList(mailListText.replaceAll(',','\n').split('\n').filter(email => !!email).map(row => ({email: row, mailSent: false})));
    },[mailListText]);
    
    // eslint-disable-next-line no-unused-vars
    const [isSending, setIsSending] = React.useState(false);

    const body = ({title, author, message, email, token, serviceId, titleId}) => `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml">
        <head>
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </head>
        <body>
            <div style="background-color: rgb(255, 255, 255); padding: 24px 0 40px 0;">
                <div>
                    <div style="margin: 0 auto; max-width: 630px; background-color: rgb(255, 255, 255); border: 1px solid rgb(236,240,241); border-radius: 12px;">
                        <div>
                            <div style="margin:0 auto; padding: 0 16px; max-width: 598px;">
                                <div style="max-width: 430px; margin: 0 auto;">
                                    <img src="https://static.webtoon.today/ddah/과제부활전2024_로고_이메일.png"
                                        alt="homepage"
                                        style="margin: 80px 0 20px 0; width: 100%; object-fit: contain;" 
                                    />
                                </div>
                                <hr style="border: 1px solid rgb(51, 51, 51); margin: 10px 0;" />
                                <div>
                                    <div style="padding: 24px 0 16px 0; font-size: 24px; font-weight: 600; line-height: 26px; color: rgb(25,24,27); word-wrap: break-word; word-break: keep-all;">
                                        로그인/회원가입 오류 해결 안내
                                    </div>
                                    <div style="padding: 24px 0 16px 0; font-size: 16px; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); ">
                                        작가님 안녕하세요.<br/>
                                        오늘의웹툰입니다.
                                    </div>
                                    <div style="padding: 0 0 16px 0; font-size: 16px; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); word-wrap: break-word; word-break: keep-all;">
                                        저희 오늘의웹툰 자유연재 플랫폼을 이용해주셔서 감사합니다. <br/>
                                        최근 경험하신 '이메일로 가입시 진행되지 않는 오류'가 해결되어 안내드립니다.
                                    </div>
                                    <div style="padding: 0 0 24px 0; font-size: 16px; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); word-wrap: break-word; word-break: keep-all;">
                                        불편을 드린 점 진심으로 사과드리며, 작가님의 작품이 더욱 빛나는 무대가 되도록 최선을 다하겠습니다. <br/>
                                        아울러 과제부활전이 진행 중이니, 혹시 아직 참여를 못하셨다면, 12월 31일 자정까지 접수를 완료해주시기 바랍니다.
                                    </div>
                                    <div style="padding: 0 0 16px 0; font-size: 16px; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); word-wrap: break-word; word-break: keep-all;">
                                        플랫폼 이용 또는 공모전과 관련하여 궁금하신 사항은 본 메일에 회신 또는 홈페이지의 채널톡으로 연락부탁드립니다. 
                                    </div>
                                    <div style="padding: 0 0 24px 0; font-size: 16px; font-weight: 500; line-height: 24px; color: rgb(121, 120, 130); word-wrap: break-word; word-break: keep-all;">
                                        오늘의웹툰 팀 드림
                                    </div>
                                    <div style="padding: 0 0 24px 0; font-size: 12px; font-weight: 500; line-height: 24px; color: rgb(150, 150, 160); word-wrap: break-word; word-break: keep-all;">
                                        * 오늘의웹툰은 오류 발생시 버그 해결 및 이에 대한 해결 공지를 위하여 버그 발생 위치와 연락처 정보를 수집하고 있으며, 버그가 해결되면 안내 후 연락처를 폐기하고 있습니다.
                                    </div>
                                    <div style="max-width: 180px; padding: 16px 0 24px 0; margin: 0 auto;">
                                        <div>
                                            <a href="https://challenge.webtoon.today/univ2024"
                                                style="text-decoration: none;">
                                                <img src="https://static.webtoon.today/ddah/mailing/component/btn_submission_start.png"
                                                    alt="공모전 접수하기"    
                                                    style="height: 40px; object-fit:contain;"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="margin: 32px auto; padding:0 16px; max-width: 598px;">
                                <div>
                                    <div style="padding-top: 24px; padding-bottom: 10px;">
                                        <div style="padding-bottom: 20px; text-align: center;">
                                            <img src="https://static.webtoon.today/ddah/mailing/logo/logo_square_big.png"
                                                alt="오늘의웹툰" 
                                                width="62px" 
                                                height="40px"
                                            />
                                        </div>
                                        <div style="text-align: center; font-size: 14px; font-weight: 400; line-height: 20px; color: rgba(158, 157, 164, 1);  word-wrap: break-word; word-break: keep-all;">
                                            사용 중 불편한 점이나 궁금한 사항이 있으시면<br/>
                                            언제든
                                            <a href="mailto:help@webtoon.today" style="color: rgba(61, 106, 255, 1);">help@webtoon.today</a>
                                            로 문의주시기 바랍니다.
                                        </div>
                                    </div>
                                    <div style="text-align: center; padding-top: 24px; font-size: 12px; font-weight: 400; line-height: 20px; color: rgba(158, 157, 164, 1);  word-wrap: break-word; word-break: keep-all;">
                                        주식회사 오늘의웹툰 <br/>
                                        서울시 강남구 강남대로 78길 33-10, 3층
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </body>
    </html>
    `
    const [mailTitle, setMailTitle] = React.useState(`[오늘의웹툰] 2024 과제부활전 일정 안내`);
    
    let {title, author, email, token, serviceId, titleId} = mailMergeList.concat([{}])[0];

    return (<div>

        <div style={{display: 'flex', justifyContent: 'center', paddingTop: '20px'}}>
            <div className={'SendEmailInner'}>
                <div className={`EmailInfo`} style={{marginBottom:10, fontSize:'0.8rem', borderBottom: '1px black solid'}}>
                    <span style={{fontWeight: 'bold'}}>{"발송 정보"}</span>
                    <table style={{width: '100%'}}>
                        <tbody>
                            <tr>
                                <td>
                                    {"제목"}
                                </td>
                                <td>
                                    <TextField
                                        value={mailTitle}
                                        onChange={(event) => {
                                            setMailTitle(event.target.value);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {"본문"}
                                </td>
                                <td>
                                    <TextField
                                        value={message}
                                        onChange={(event) => {
                                            setMessage(event.target.value);
                                        }}
                                        multiline={3}
                                        fullWidth
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={`EmailPreview`}>
                    <div dangerouslySetInnerHTML={{__html: body({title, author, message, email, token, serviceId, titleId})}} />
                </div>
            </div>
        </div>
        <div className={'SendButton'}>
            <Button 
                fullWidth
                disabled={isSending}
                variant={'contained'} color={'primary'} 
                onClick={ async () => {
                    setIsSending(true);

                    for (let index = 0; index < mailMergeList.length; index++){
                        let row = mailMergeList[index];
                        let {title, author, token, serviceId, titleId,
                            email, 
                        } = row;
                        const result = await triggerEmail(email,mailTitle,body({title, author, message, email, token, serviceId, titleId}),'help@webtoon.today')
                        if (!result) {
                            alert('이메일 전송에 실패했습니다.')
                        }else {
                            setMailMergeList(mailMergeList => [
                                ...mailMergeList.slice(0,index),
                                {...row, mailSent: true},
                                ...mailMergeList.slice(index+1)
                            ])
                        }
                    }
                    setIsSending(false);
                }}
            >
                {'이메일 보내기'}
            </Button>
        </div>
        <div className={''}>{"이메일 수신 대상자(, 또는 다음줄로 구분해주세요)"}</div>
        <TextField multiline fullWidth value={mailListText} onChange={(event) => { setMailListText(event.target.value); }} />
        <div className={''}></div>
        <table>
            <td>{"이메일"}</td>
            <td>{"발송여부"}</td>
            {mailMergeList.map(row => {
                return (<tr>
                    <td>{row.email}</td>
                    <td>{JSON.stringify(row.mailSent)}</td>
                </tr>)
            })}
        </table>
    </div>);
};

export default NoticeByEmail;